import { Logger, useVSFContext } from '@vue-storefront/core';
import { computed } from '@vue/composition-api';
import { DISCOUNT_FIELDS } from '~/constants/commerceTools';
import { parseVolumeDiscounts } from '~/helpers/voumeDiscounts/parseVolumeDiscounts';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  VolumeDiscountsGetters,
  VolumeDiscountsMutations,
  VolumeDiscountsState
} from '~/types/vuex/VolumeDiscounts';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default () => {
  const { $ct } = useVSFContext();

  const store = defineModule<
    typeof MODULE_NAMES.VOLUME_DISCOUNTS_MODULE_NAME,
    VolumeDiscountsMutations,
    VolumeDiscountsState,
    VolumeDiscountsGetters
  >(MODULE_NAMES.VOLUME_DISCOUNTS_MODULE_NAME);

  const volumeDiscountGroups = computed(() => store.state.volumeDiscountGroups);

  const isGroupActive = (groupName: string) => {
    return store.getters.isGroupActive.value(groupName);
  };

  const load = async (discountGroup: string) => {
    if (volumeDiscountGroups.value[discountGroup]) {
      return;
    }
    try {
      const res = await $ct.api.getVolumeDiscounts({
        discountGroup,
        sort: [`${DISCOUNT_FIELDS.SORT_ORDER} asc`]
      });
      store.mutations.setGroup({
        groupKey: discountGroup,
        value: parseVolumeDiscounts(res?.data?.cartDiscounts?.results)
      });
    } catch (err) {
      Logger.error('error.useVolumeDiscount', String(err));
    }
  };

  return {
    isGroupActive,
    volumeDiscountGroups,
    load
  };
};
