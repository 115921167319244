import { useShippingProvider } from '@vsf-enterprise/commercetools';
import { ref, reactive, computed, onMounted } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { useCartExtended } from '~/composables';
import { ShippingMethodsResponse } from '~/types/checkout/ShippingMethod';
import { usePromiseQueue } from '~/composables/modules/usePromiseQueue';
import { CART_PROMISE_QUEUE_KEY } from '~/constants/checkout';
import callFunctionWithRetries from '~/helpers/retry/callFunctionWithRetries';
import { errorToString } from '~/helpers/error/errorToString';

export default () => {
  const loading = ref(false);
  const shippingMethodsDataKey = 'shipping-methods-data-key';
  const { $ct, $cache } = useVSFContext();
  const { cart, reloadCart } = useCartExtended();
  const methodsWithoutId = sharedRef<ShippingMethodsResponse['shippingMethods']>([], shippingMethodsDataKey);
  const {
    state,
    error: errorShippingProvider,
    loading: loadingShippingProvider,
    save: oldSave,
    load
  } = useShippingProvider();
  const selectedShippingMethod = computed(
    () => state?.value?.response?.shippingMethod);
  type ShippingMethod = typeof selectedShippingMethod['value'];

  const save = usePromiseQueue(oldSave, CART_PROMISE_QUEUE_KEY).execute;

  const error = reactive<{
    loadMethods: null | string | Error;
    selectShippingMethod: null | string | Error;
    loadMethodsWithoutId: null | string | Error;
  }>({
    loadMethods: null,
    selectShippingMethod: null,
    loadMethodsWithoutId: null
  });

  const loadProviderIfNeeded = async () => {
    if (cart.value && !state.value) {
      await load();
    }
  };

  onMounted(async () => {
    await loadProviderIfNeeded();
  });

  const loadMethodsWithoutId = async () => {
    if (methodsWithoutId.value?.length) return;

    error.loadMethodsWithoutId = null;
    try {
      let shippingMethods = await $cache.data.get(shippingMethodsDataKey);

      if (!shippingMethods) {
        shippingMethods = await $ct.api.getShippingMethodsWithoutId();
        await $cache.data.set(shippingMethodsDataKey, shippingMethods, [shippingMethodsDataKey]);
      }

      methodsWithoutId.value = shippingMethods?.data?.shippingMethods;
    } catch (err) {
      Logger.error('error.loadMethodsWithoutId = true ', String(err));
      error.loadMethodsWithoutId = err;
    }
  };

  const loadMethods = async (): Promise<ShippingMethod[] | null> => {
    try {
      error.loadMethods = null;
      const shippingMethodsResponse = await $ct.api.getShippingMethods(cart.value.id);
      return shippingMethodsResponse.data.shippingMethods;
    } catch (err) {
      const message = errorToString(err);
      Logger.error('useShippingMethods.loadMethods: ', message);
      error.loadMethods = message;
      await reloadCart();
      throw err;
    }
  };

  const selectShippingMethod = (shippingMethod: ShippingMethod) => callFunctionWithRetries(async () => {
    try {
      if (loadingShippingProvider.value) {
        return;
      }
      error.selectShippingMethod = null;
      await save({
        shippingMethod,
        customQuery: CUSTOM_QUERIES.UPDATE_CART_CUSTOM
      });
      error.selectShippingMethod = errorShippingProvider.value?.save;
      if (error.selectShippingMethod) {
        throw error.selectShippingMethod;
      }
    } catch (err) {
      const message = errorToString(err);
      Logger.error('useShippingMethods.selectShippingMethod: ', message);
      error.selectShippingMethod = message;
      await reloadCart();
      throw message;
    }
  });

  const selectDefaultShippingMethod = () => callFunctionWithRetries(async () => {
    loading.value = true;
    const shippingMethods = await loadMethods();
    if (!shippingMethods || error.loadMethods) {
      throw new Error('There is no shipping method to select');
    }
    await loadProviderIfNeeded();
    await selectShippingMethod(shippingMethods[0]);
    if (error.selectShippingMethod) {
      throw new Error('Error during shipping method selection: ' + error.selectShippingMethod);
    }
    loading.value = false;
  });

  const isShippingSelected = computed(() => !!selectedShippingMethod.value?.zoneRates);

  return {
    isShippingSelected,
    selectDefaultShippingMethod,
    loading,
    error,
    loadMethodsWithoutId,
    methodsWithoutId,
    selectedShippingMethod
  };
};
