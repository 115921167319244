export const errorToString = (e: unknown): string => {
  if ((e instanceof Object && 'message' in e) ||
    e instanceof Error) {
    return e.message;
  } else if (String(e) === '[object Object]') {
    return JSON.stringify(e);
  } else {
    return String(e);
  }
};
