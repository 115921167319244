import { ref } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';
import { useIntegrations } from '~/composables';
export const useRecommendationsShownEvent = ({ slug, recommendationId }:
  { slug: string; recommendationId: string }) => {
  const { $cia } = useIntegrations();
  const hasTriggered = ref(false);
  const onIntersect = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ): void => {
    const entry = entries[0];
    if (entry && entry.isIntersecting && !hasTriggered.value) {
      hasTriggered.value = true;
      try {
        $cia.event.recommendationsShown(
          recommendationId,
          slug
        );
      } catch (error) {
        Logger.error(`cia|useRecommendationsShownEvent error: ${error}`);
      }
      observer.unobserve(entry.target);
    }
  };
  return {
    onIntersect
  };
};
