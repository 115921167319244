import { AxiosResponse } from 'axios';
import { RecommendationsShownEvent } from '~/types/integrations/cia/event/recommendations-shown/RecommendationsShown';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';
export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { locale, posthogDistinctId } = instance.state;
  return (recommendationId: string, slug?:string): Promise<AxiosResponse> => {
    const payload: RecommendationsShownEvent = {
      locale: locale.value,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      posthog_identifier: posthogDistinctId.value,
      slug,
      recommendation_id: recommendationId
    };
    return axios.post('recommendations-shown', payload);
  };
};
